<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    class="white pa-0"
  >
    <v-banner
      v-if="deferredPrompt"
      color="info"
      dark
      class="text-left"
    >
      {{ $t('app.popup') }}

      <template v-slot:actions>
        <v-btn
          text
          @click="dismiss"
        >
          {{ $t("app.buttons.dismiss") }}
        </v-btn>
        <v-btn
          text
          @click="install"
        >
          {{ $t("app.buttons.install") }}
        </v-btn>
      </template>
    </v-banner>
    <v-snackbar
      v-model="hasOpenApplications"
      color="primary"
    >
      <span
        style="font-size: 1.2rem;cursor:pointer"
        @click="goTo('/userapplications')"
      >
        {{ $t('common.openApplicationNotification') }}
      </span>
      <template v-slot:action>
        <v-icon
          color="secondary"
          variant="text"
          @click="hasOpenApplications = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <div class="hidden-md-and-down">
      <hero-component
        :btn-text="$t('dashboard.section1.applyNowBtn')"
        btn-color="#00A1B7"
        btn-text-color="#FFFFFF"
        background-url="/img/blanketmain-v1.jpg"
        @click="scrollToSection2"
        align="flex-start"
        gap="20px"
        class="hero-custom-style"
      >
        {{ $t("dashboard.title.part1") }}<br />
        {{ $t("dashboard.title.part2") }}
      </hero-component>
      <div class="section2-desktop hidden-sm-and-down">
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center">
            {{ $t("dashboard.section2.h1") }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="text-center sec2-dsk-sub">
            {{ $t("dashboard.section2.h2") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            {{ $t("dashboard.section2.copy1") }} <br />
            {{ $t("dashboard.section2.copy2") }} <br />
            {{ $t("dashboard.section2.copy3") }}
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center pb-0">
            {{ $t("dashboard.section3.h1") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            v-if="!userCanadian"
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              v-for="(product, i) in productCardsUSA"
              :key="product.id"
              :index="i"
              class="d-flex flex-column justify-center align-center"
            >
              <v-card
                width="180px"
                height="242"
                class="product-card"
                :class="product.status === 'active' ? 'zoom' : 'inactive'"
                elevation="3"
                @click="product.status === 'active' && goTo(product.route)"
              >
                <v-row>
                  <v-col class="pb-0">
                    <div
                      height="122"
                      width="112"
                    >
                      <v-img
                        :src="product.img"
                        class="mx-auto mt-2 prd-img"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="prd-card-title">
                    {{ $t(product.title) }}
                  </v-col>
                </v-row>
                <v-row class="w-100">
                  <v-col class="px-0">
                    <div class="product-tag pt-2">
                      {{ $t(product.tag) }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <div class="pl-5">
                <v-btn
                  :class="
                    $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                      ? 'learn-btn'
                      : 'coming-btn'
                  "
                  @click="product.status === 'active' && goTo(product.learnRoute)"
                >
                  <span
                    :class="
                      $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                        ? 'lrn-btn-txt'
                        : 'coming-txt'
                    "
                  >{{ $t(product.txt) }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-col>
          <v-col
            v-if="userCanadian"
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              v-for="(product, i) in productCardsCA"
              :key="product.id"
              :index="i"
              class="d-flex flex-column justify-center align-center"
            >
              <v-card
                width="180px"
                height="242"
                class="product-card"
                :class="product.status === 'active' ? 'zoom' : 'inactive'"
                elevation="3"
                @click="product.status === 'active' && goTo(product.route)"
              >
                <v-row>
                  <v-col class="pb-0">
                    <div
                      height="122"
                      width="112"
                    >
                      <v-img
                        :src="product.img"
                        class="mx-auto mt-2 prd-img"
                      ></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="prd-card-title">
                    {{ $t(product.title) }}
                  </v-col>
                </v-row>
                <v-row class="w-100">
                  <v-col class="px-0">
                    <div class="product-tag pt-2">
                      {{ $t(product.tag) }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <div class="pl-5">
                <v-btn
                  :class="
                    $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                      ? 'learn-btn'
                      : 'coming-btn'
                  "
                  @click="product.status === 'active' && goTo(product.learnRoute)"
                >
                  <span
                    :class="
                      $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                        ? 'lrn-btn-txt'
                        : 'coming-txt'
                    "
                  >{{ $t(product.txt) }}</span>
                </v-btn>
              </div>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-dsk">
        <v-row class="pt-16">
          <v-col class="sec3-dsk-title">
            {{ $t("dashboard.section4.h1") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8">
          <v-col
            cols="10"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <div
                height="48"
                width="48"
              >
                <v-img src="/img/b-icon.png"></v-img>
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-account-group
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-trophy-award
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.section4.tiles.tile1.h1.part1") }}<br />{{
                $t("dashboard.section4.tiles.tile1.h1.part2")
              }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.section4.tiles.tile2.h1.part1") }}<br />{{
                $t("dashboard.section4.tiles.tile2.h1.part2")
              }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.section4.tiles.tile3.h1.part1") }}<br />{{
                $t("dashboard.section4.tiles.tile3.h1.part2")
              }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.section4.tiles.tile1.copy.part1") }}<br />
              {{ $t("dashboard.section4.tiles.tile1.copy.part2") }} <br />
              {{ $t("dashboard.section4.tiles.tile1.copy.part3") }} <br />
              {{ $t("dashboard.section4.tiles.tile1.copy.part4") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.section4.tiles.tile2.copy.part1") }}<br />
              {{ $t("dashboard.section4.tiles.tile2.copy.part2") }} <br />
              {{ $t("dashboard.section4.tiles.tile2.copy.part3") }} <br />
              {{ $t("dashboard.section4.tiles.tile2.copy.part4") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.section4.tiles.tile3.copy.part1") }}<br />
              {{ $t("dashboard.section4.tiles.tile3.copy.part2") }} <br />
              {{ $t("dashboard.section4.tiles.tile3.copy.part3") }} <br />
              {{ $t("dashboard.section4.tiles.tile3.copy.part4") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-6">
          <v-col
            cols="10"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-face-agent
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-cash-check
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-account-box-multiple
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.section4.tiles.tile4.h1.part1") }} <br />
              {{ $t("dashboard.section4.tiles.tile4.h1.part2") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.section4.tiles.tile5.h1.part1") }} <br />
              {{ $t("dashboard.section4.tiles.tile5.h1.part2") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.section4.tiles.tile6.h1.part1") }} <br />
              {{ $t("dashboard.section4.tiles.tile6.h1.part2") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex pt-0"
          >
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.section4.tiles.tile4.copy.part1") }} <br />
              {{ $t("dashboard.section4.tiles.tile4.copy.part2") }} <br />{{
                $t("dashboard.section4.tiles.tile4.copy.part3")
              }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.section4.tiles.tile5.copy.part1") }} <br />
              {{ $t("dashboard.section4.tiles.tile5.copy.part2") }} <br />
              {{ $t("dashboard.section4.tiles.tile5.copy.part3") }} <br />
              {{ $t("dashboard.section4.tiles.tile5.copy.part4") }} <br />
              {{ $t("dashboard.section4.tiles.tile5.copy.part5") }} <br />
              {{ $t("dashboard.section4.tiles.tile5.copy.part6") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.section4.tiles.tile6.copy") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center">
          <v-col class="text-center ml-6">
            <v-btn
              large
              class="mint apply-btn-txt"
              @click="scrollToSection2"
            >
              {{ $t("dashboard.section4.startPolicyBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="news-section">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h1 class="text-center mb-5">
                Latest news from Blanket
              </h1>
            </v-col>
            <v-col cols="6">
              <div class="pr-10">
                <h1 class="news-title">
                  {{ news.title }}
                </h1>
                <p>
                  {{ news.description }}
                </p>
                <v-btn
                  class="learn-btn"
                  :href="news.link"
                >
                  <span class="lrn-btn-txt">
                    READ MORE
                  </span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6">
              <v-img
                :src="news.image"
                class="w-100"
              ></v-img>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                large
                class="mint  apply-btn-txt"
                to="/about/news"
              >
                ALL NEWS
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
      <div class="sec5-dsk">
        <div class="sec5-cnt">
          <v-row>
            <v-col class="sec5-title">
              {{ $t("dashboard.section4.h2") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="sec5-sub">
              {{ $t("dashboard.section4.copy") }}
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                @click="scrollToSection2"
              >
                {{ $t("dashboard.section4.startPolicyBtn") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-img
          src="/img/dashboard/mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <testimonial-carosuel
        :reviews="reviews"
        :current-index="currentIndex"
        :auto-play="true"
        :interval-duration="5000"
      />
      <div class="sec7-dsk">
        <div class="about-frame">
          <v-row>
            <v-col class="sec5-title">
              {{ $t("dashboard.section6.h1") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="about-txt">
              {{ $t("dashboard.section6.para1") }}<br />
              <br />{{ $t("dashboard.section6.para2") }}
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                to="/about/team"
              >
                {{ $t("dashboard.section6.h1") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-img
            src="/img/Generali - logo.png"
            class="generali-logo"
          ></v-img>
          <v-img
            src="/img/stillwater - logo.png"
            class="stillwater-logo"
          ></v-img>
          <v-img
            src="/img/afficiency - logo.png"
            class="afficiency-logo"
          ></v-img>
          <v-img
            src="/img/tugo - logo.png"
            class="tugo-logo"
            contain
          ></v-img>
          <v-img
            src="/img/humania-logo.png"
            class="humania-logo"
            contain
          ></v-img>
        </div>
      </div>
    </div>
    <!--------------------------------TABLET----------------------------->
    <div v-show="mdDevice || smDevice">
      <hero-component
        :btn-text="$t('dashboard.section1.applyNowBtn')"
        btn-color="#00A1B7"
        btn-text-color="#FFFFFF"
        background-url="/img/blanketmain-v1.jpg"
        @click="scrollToSection2"
        align="flex-start"
        gap="20px"
        class="hero-custom-style"
      >
        {{ $t("dashboard.tablet.title.part1") }} <br />
        {{ $t("dashboard.tablet.title.part2") }}
      </hero-component>
      <div class="section2-tablet py-16">
        <v-row>
          <v-col class="sec2-dsk-title d-flex justify-center">
            {{ $t("dashboard.section2.h1") }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="text-center sec2-dsk-sub">
            {{ $t("dashboard.section2.h2") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            {{ $t("dashboard.section2.copy1") }} <br />
            {{ $t("dashboard.section2.copy2") }} <br />
            {{ $t("dashboard.section2.copy3") }}
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center pb-0">
            {{ $t("dashboard.section3.h1") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center flex-wrap mt-0 px-6">
          <v-col
            v-for="(product, i) in productCards"
            :key="product.id"
            cols="4"
            :index="i"
            class="d-flex flex-column justify-center align-center"
          >
            <v-card
              width="180px"
              height="242"
              class="product-card"
              :class="product.status === 'active' ? 'zoom' : 'inactive'"
              elevation="3"
              @click="goTo(product.route)"
            >
              <v-row>
                <v-col class="pb-0">
                  <div
                    height="122"
                    width="112"
                  >
                    <v-img
                      :src="product.img"
                      class="mx-auto mt-2 prd-img"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="prd-card-title">
                  {{ $t(product.title) }}
                </v-col>
              </v-row>
              <v-row class="w-100">
                <v-col class="px-0">
                  <div class="product-tag pt-2">
                    {{ $t(product.tag) }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <div class="pl-5">
              <v-btn
                :class="
                  $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                    ? 'learn-btn'
                    : 'coming-btn'
                "
                @click="product.status === 'active' && goTo(product.learnRoute)"
              >
                <span
                  :class="
                    $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                      ? 'lrn-btn-txt'
                      : 'coming-txt'
                  "
                >{{ $t(product.txt) }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-tablet py-16">
        <v-row>
          <v-col class="sec3-dsk-title">
            {{ $t("dashboard.tablet.section4.h1") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8">
          <v-col
            cols="12"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <div
                height="48"
                width="48"
              >
                <v-img src="/img/b-icon.png"></v-img>
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-account-group
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-trophy-award
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.tablet.section4.tiles.tile1.h1") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.tablet.section4.tiles.tile2.h1") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.tablet.section4.tiles.tile3.h1") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.tablet.section4.tiles.tile1.copy") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.tablet.section4.tiles.tile2.copy") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.tablet.section4.tiles.tile3.copy") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-6">
          <v-col
            cols="12"
            class="d-inline-flex justify-center pb-0"
          >
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-face-agent
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-cash-check
              </v-icon>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-icon class="mint--text dash-icons">
                mdi-account-box-multiple
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.tablet.section4.tiles.tile4.h1") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.tablet.section4.tiles.tile5.h1") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-sub">
              {{ $t("dashboard.tablet.section4.tiles.tile6.h1") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex pt-0"
          >
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.tablet.section4.tiles.tile4.copy") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.tablet.section4.tiles.tile5.copy") }}
            </v-col>
            <v-col class="d-flex justify-center sec3-txt">
              {{ $t("dashboard.tablet.section4.tiles.tile6.copy") }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center">
          <v-col class="text-center ml-6">
            <v-btn
              large
              class="mint apply-btn-txt"
              @click="scrollToSection2"
            >
              {{ $t("dashboard.section4.startPolicyBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="news-section-tablet">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h1 class="text-center mb-5">
                Latest news from Blanket
              </h1>
            </v-col>
            <v-col cols="6">
              <div class="pr-10">
                <h1 class="news-title">
                  {{ news.title }}
                </h1>
                <p>
                  {{ news.description }}
                </p>
                <v-btn
                  class="learn-btn"
                  :href="news.link"
                >
                  <span class="lrn-btn-txt">
                    READ MORE
                  </span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6">
              <v-img
                :src="news.image"
                class="w-100"
              ></v-img>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                large
                class="mint  apply-btn-txt"
                to="/about/news"
              >
                ALL NEWS
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
      <div class="sec5-tablet py-8">
        <v-row>
          <v-col class="sec5-title text-center">
            {{ $t("dashboard.section4.h2") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub text-center">
            {{ $t("dashboard.section4.copy") }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-btn
              large
              class="mint apply-btn-txt mr-0"
              @click="scrollToSection2"
            >
              {{ $t("dashboard.section4.startPolicyBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <testimonial-carosuel
        :reviews="reviews"
        :current-index="currentIndex"
        :auto-play="true"
        :interval-duration="5000"
      />
      <div class="sec7-tablet pa-16">
        <v-row>
          <v-col class="sec5-title">
            {{ $t("dashboard.section6.h1") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="about-txt">
            {{ $t("dashboard.section6.para1") }} <br />
            <br />{{ $t("dashboard.section6.para2") }}
          </v-col>
        </v-row>
        <v-row class="d-flex align-end">
          <v-col class="pb-8">
            <v-img
              src="/img/Generali - logo.png"
              class="generali-logo-tablet"
            ></v-img>
          </v-col><v-col>
            <v-img
              src="/img/stillwater - logo.png"
              class="stillwater-logo-tablet"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-end">
          <v-col>
            <v-img
              src="/img/afficiency - logo.png"
              class="afficiency-logo-tablet"
            ></v-img>
          </v-col>
          <v-col>
            <v-img
              src="/img/tugo - logo.png"
              class="tugo-logo-tablet"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-end">
          <v-col>
            <v-img
              src="/img/humania-logo.png"
              class="humania-logo-tablet"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col>
            <v-btn
              large
              class="mint apply-btn-txt"
              to="/about/team"
            >
              {{ $t("dashboard.section6.learnMoreBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <!---------------------------------MOBILE----------------------------->
    <div v-show="xsDevice">
      <div class="section1-mobile"></div>
      <div class="primary pa-6">
        <v-row>
          <v-col
            cols="12"
            class="section1-title-mobile"
          >
            {{ $t("dashboard.mobile.title") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              large
              class="mint apply-btn-txt mr-0"
              @click="scrollToSection2"
            >
              {{ $t("dashboard.section1.applyNowBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section2-tablet py-16 px-2">
        <v-row>
          <v-col class="sec2-dsk-title d-flex justify-center">
            {{ $t("dashboard.section2.h1") }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="text-center sec2-dsk-sub">
            {{ $t("dashboard.section2.h2") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            {{ $t("dashboard.mobile.section2.desc") }}
          </v-col>
        </v-row>
        <v-row class="mt-15">
          <v-col class="sec2-dsk-title d-flex justify-center pb-0">
            {{ $t("dashboard.section3.h1") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center flex-wrap mt-0">
          <v-col
            v-for="(product, i) in productCards"
            :key="product.id"
            cols="6"
            :index="i"
            class="d-flex flex-column justify-center align-center pa-2"
          >
            <v-card
              width="180px"
              height="242"
              class="product-card"
              :class="product.status === 'active' ? 'zoom' : 'inactive'"
              elevation="3"
              @click="goTo(product.route)"
            >
              <v-row>
                <v-col class="pb-0">
                  <div
                    height="122"
                    width="112"
                  >
                    <v-img
                      :src="product.img"
                      class="mx-auto mt-2 prd-img"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="prd-card-title">
                  {{ $t(product.title) }}
                </v-col>
              </v-row>
              <v-row class="w-100">
                <v-col class="px-0">
                  <div class="product-tag pt-2">
                    {{ $t(product.tag) }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <div class="pl-5">
              <v-btn
                :class="
                  $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                    ? 'learn-btn'
                    : 'coming-btn'
                "
                @click="product.status === 'active' && goTo(product.learnRoute)"
              >
                <span
                  :class="
                    $t(product.txt) === $t('dashboard.section3.learnMoreBtn')
                      ? 'lrn-btn-txt'
                      : 'coming-txt'
                  "
                >{{ $t(product.txt) }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-mobile py-16 px-3">
        <v-row>
          <v-col class="sec3-dsk-title">
            {{ $t("dashboard.tablet.section4.h1") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-8">
          <v-col
            cols="12"
            class="d-inline-flex justify-center"
          >
            <div
              height="48"
              width="48"
            >
              <v-img src="/img/b-icon.png"></v-img>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile1.h1") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile1.copy") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center pb-0 pt-10"
          >
            <v-icon class="mint--text dash-icons">
              mdi-account-group
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile2.h1") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile2.copy") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center pt-10"
          >
            <v-icon class="mint--text dash-icons">
              mdi-trophy-award
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile3.h1") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile3.copy") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center pt-10"
          >
            <v-icon class="mint--text dash-icons">
              mdi-face-agent
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile4.h1") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile4.copy") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon class="mint--text dash-icons pt-10">
              mdi-cash-check
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile5.h1") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile5.copy") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon class="mint--text dash-icons pt-10">
              mdi-account-box-multiple
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-sub py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile6.h1") }}
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center sec3-txt py-0"
          >
            {{ $t("dashboard.tablet.section4.tiles.tile6.copy") }}
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center">
          <v-col class="text-center pt-10">
            <v-btn
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              @click="scrollToSection2"
            >
              {{ $t("dashboard.section4.startPolicyBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <div class="news-section-mobile">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h1 class="text-center mb-5">
                Latest news from Blanket
              </h1>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <h1 class="news-title">
                  {{ news.title }}
                </h1>
                <p>
                  {{ news.description }}
                </p>
                <v-btn
                  class="learn-btn"
                  :href="news.link"
                >
                  <span class="lrn-btn-txt">
                    READ MORE
                  </span>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12 px-0">
              <v-img
                :src="news.image"
                class="w-100"
              ></v-img>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                large
                class="mint  apply-btn-txt"
                to="/about/news"
              >
                ALL NEWS {{ id }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
      <div class="sec5-mobile py-8 px-2">
        <v-row>
          <v-col class="sec5-title text-center">
            {{ $t("dashboard.section4.h2") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub text-center">
            {{ $t("dashboard.section4.copy") }}
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-btn
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              @click="scrollToSection2"
            >
              {{ $t("dashboard.section4.startPolicyBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <testimonial-carosuel
        :reviews="reviews"
        :current-index="currentIndex"
        :auto-play="true"
        :interval-duration="5000"
      />
      <div class="sec7-mobile py-16 px-2">
        <v-row>
          <v-col class="sec5-title">
            {{ $t("dashboard.section6.h1") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="about-txt">
            {{ $t("dashboard.section6.para1") }} <br />
            <br />{{ $t("dashboard.section6.para2") }}
          </v-col>
        </v-row>
        <v-row class="d-flex align-end">
          <v-col class="pb-8 px-auto">
            <v-img
              src="/img/Generali - logo.png"
              class="generali-logo-tablet mx-auto"
            ></v-img>
          </v-col><v-col>
            <v-img
              src="/img/stillwater - logo.png"
              class="stillwater-logo-tablet mx-auto"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/afficiency - logo.png"
              class="afficiency-logo-tablet mx-auto"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/tugo - logo.png"
              class="tugo-logo-tablet mx-auto"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-img
              src="/img/humania-logo.png"
              class="humania-logo-tablet mx-auto"
              contain
            ></v-img>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col class="d-flex justify-center">
            <v-btn
              large
              class="mint text-h5 darkgrey--text font-weight-bold mr-0"
              to="/about/team"
            >
              {{ $t("dashboard.section6.learnMoreBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <otp-verification />
  </v-container>
</template>
<script>
  import HeroComponent from '@/components/base/Hero'
  import TestimonialCarosuel from '@/components/base/TestimonialCarousel'
  import { currentApplications } from '@/utils/common'
  export default {
    name: 'Dashboard',
    components: {
      HeroComponent,
      TestimonialCarosuel
    },
    data () {
      return {
        deferredPrompt: null,
        reviews: '',
        currentIndex: 0,
        news: {
          title: 'News title',
          description:
            'Lorem ipsum dolor sit amet consectetur. Duis etiam fusce nisl ut odio fringilla in. Ut tempus dolor nunc nulla aenean ridiculus. Ipsum amet lobortis mattis pharetra odio lobortis interdum...',
          image: '/img/news/news-1.png',
          link: '/about/news/details',
        },
        id: '',
        symetrapdf: '',
        tabs: 0,
        model: null,
        show: false,
        productCards: [],
        productCardsUSA: [
          {
            title: 'dashboard.section3.tiles.life.h1',
            id: 4,
            route: '/afficiencyterm',
            learnRoute: '/afficiencyterm',
            img: '/img/product-cards/life.png',
            tag: 'dashboard.section3.tiles.life.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          {
            title: 'dashboard.section3.tiles.travel.h1',
            id: 1,
            route: '/travel/usa',
            learnRoute: '/travel/usa',
            img: '/img/product-cards/travel.png',
            tag: 'dashboard.section3.tiles.travel.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          // {
          //   title: 'dashboard.section3.tiles.pet.h1',
          //   id: 6,
          //   route: '/pet',
          //   learnRoute: '/pet',
          //   img: '/img/product-cards/pet.png',
          //   tag: 'dashboard.section3.tiles.pet.desc',
          //   txt: 'dashboard.section3.learnMoreBtn',
          //   status: 'active',
          // },
          {
            title: 'dashboard.section3.tiles.renters.h1',
            id: 3,
            route: '/renters',
            learnRoute: '/renters',
            img: '/img/product-cards/renter.png',
            tag: 'dashboard.section3.tiles.renters.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          {
            title: 'dashboard.section3.tiles.condo.h1',
            id: 2,
            route: '/condo',
            learnRoute: '/condo',
            img: '/img/product-cards/condo.png',
            tag: 'dashboard.section3.tiles.condo.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          // {
          //   title: 'dashboard.section3.tiles.auto.h1',
          //   id: 5,
          //   route: '#',
          //   learnRoute: '#',
          //   img: '/img/product-cards/auto.png',
          //   height: 122,
          //   width: 112,
          //   tag: 'dashboard.section3.tiles.auto.desc',
          //   txt: 'dashboard.section3.comingSoonBtn',
          //   status: 'cs',
          // },
        ],
        productCardsCA: [
          {
            title: 'dashboard.section3.tiles.life.h1',
            id: 4,
            route: '/canadianterm',
            learnRoute: '/canadianterm',
            img: '/img/product-cards/life.png',
            tag: 'dashboard.section3.tiles.life.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          {
            title: 'dashboard.section3.tiles.travel.h1',
            id: 1,
            route: '/travel/ca',
            learnRoute: '/travel/ca',
            img: '/img/product-cards/travel.png',
            tag: 'dashboard.section3.tiles.travel.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          // {
          //   title: 'dashboard.section3.tiles.pet.h1',
          //   id: 6,
          //   route: '/pet-ca',
          //   learnRoute: '/pet-ca',
          //   img: '/img/product-cards/pet.png',
          //   tag: 'dashboard.section3.tiles.pet.desc',
          //   txt: 'dashboard.section3.learnMoreBtn',
          //   status: 'active',
          // },
          {
            title: 'dashboard.section3.tiles.renters.h1',
            id: 3,
            route: '/renters-ca',
            learnRoute: '/renters-ca',
            img: '/img/product-cards/renter.png',
            tag: 'dashboard.section3.tiles.renters.desc',
            txt: 'dashboard.section3.learnMoreBtn',
            status: 'active',
          },
          {
            title: 'dashboard.section3.tiles.condo.h1',
            id: 2,
            route: '#',
            learnRoute: '#',
            img: '/img/product-cards/condo.png',
            tag: 'dashboard.section3.tiles.condo.desc',
            txt: 'dashboard.section3.comingSoonBtn',
            status: 'cs',
          },
          // {
          //   title: 'dashboard.section3.tiles.auto.h1',
          //   id: 5,
          //   route: '#',
          //   learnRoute: '#',
          //   img: '/img/product-cards/auto.png',
          //   height: 122,
          //   width: 112,
          //   tag: 'dashboard.section3.tiles.auto.desc',
          //   txt: 'dashboard.section3.comingSoonBtn',
          //   status: 'cs',
          // },
        ],
        categories: [],
        showncategories: [],
        filteredproducts: [],
        getStarted: false,
        showTitle: true,
        currentCountry: 'US',
        hasOpenApplications: false,
        hasSeenAppNotification: false
      }
    },
    computed: {
      userCanadian () {
        this.productCards = this.productCardsUSA
        if (this.$store.getters.getWebsiteLocation === 'CA') {
          this.productCards = this.productCardsCA
          return true
        }
        return false
      },
      getuserkey () {
        let key = ''
        key = this.$store.getters?.getuser?.id
        return key
      },
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      lgDevice () {
        return this.$vuetify.breakpoint.lgOnly
      },
      mdDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
      smDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      xsDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      symetrapdffile () {
        let pdf = ''
        pdf = this.$store.getters.getSymetraPdfs[0]
        return pdf
      },
      productlist () {
        return this.filteredproducts
      },
      getCurrentCountry () {
        return this.$store.getters.getWebsiteLocation
      },
    },
    watch: {
      getuserkey (value) {
        if (value) {
          this.hasOpenApplications = currentApplications(this.$store?.state?.user?.applications ?? []).length > 0
        }
      },
      getCurrentCountry (value) {
        const getCaProducts = this.productCardsCA
        const isCanadianFrench = value === 'CA' && this.$Storage.getCookie('locale') === 'fr'
        const status = isCanadianFrench ? 'cs' : 'active'
        const text = isCanadianFrench ? 'dashboard.section3.comingSoonBtn' : 'dashboard.section3.learnMoreBtn'

        this.productCardsCA.forEach(item => {
          if (['dashboard.section3.tiles.travel.h1', 'dashboard.section3.tiles.renters.h1'].includes(item.title)) {
            item.status = status
            item.txt = text
          }
        })
      },
    },
    mounted () {
      this.reviews = this.$t('dashboard.section5.reviews')
      if (this.getuserkey && this.$store.state.showApplicationNotification) {
        this.hasOpenApplications = currentApplications(this.$store?.state?.user?.applications ?? []).length > 0
        this.$store.commit('setShowApplicationNotification', false)
      }
    },
    created () {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
      })
      window.addEventListener('appinstalled', () => {
        this.deferredPrompt = null
      })
      this.id = this.$route.params.id
      console.log('PARAMS', this.$route.params)
      if (this.$route.params.partnername) {
        let campaignid = 'unknown'
        let partnername = 'unknown'
        partnername = this.$route.params.partnername
        this.$store.dispatch('setPartnerId', partnername)
        console.log({ partnername })
        if (this.$route.params.campaignid) {
          campaignid = this.$route.params.campaignid
          this.$store.dispatch('setCampaignId', campaignid)
        }
        this.$gtag.event('blanketpartner', {
          method: partnername,
          campaign: campaignid,
        })
        this.$gtag.pageview('Dashboard' + '-' + partnername)
      }
      this.$gtag.pageview('Dashboard')
      this.currentuser = this.$store.getters.getuser
      this.products = this.$store.getters.getProducts
      this.categories = this.$store.getters.getCategories
      this.showncategories = this.categories
      this.filteredproducts = this.products
      // this.$store.dispatch('loadSymetraNotices')
      // this.$store.dispatch('submitUSTermApplicationAnswers', '')
      // this.$store.dispatch('requestUSTermApplicationQuestions')
      let rec = {
        state: 'AL',
      }
    // this.$store.dispatch('loadSymetraNotices', rec)
    // this.$store.dispatch('symetraWOPRates', { faceamount: '500000', nearestage: '30', termlength: '20' })
    // this.$store.dispatch('symetraADBRates', { faceamount: '250000', nearestage: '30', gender: 'Male' })
    // this.$store.dispatch('symetraICBRates', [{ faceamount: '2000' }, { faceamount: '4000' }, { faceamount: '10000' }])
    },
    methods: {
      async dismiss () {
        this.deferredPrompt = null
      },
      async install () {
        this.deferredPrompt.prompt()
      },
      // changeLinkBasedOnLocation () {
      //   setTimeout(() => {
      //     this.currentCountry = this.$store.getters.getWebsiteLocation
      //     console.log('Dashboard', this.currentCountry)
      //     this.productCards.forEach((product) => {
      //       if (product.id === 6 && this.currentCountry === 'CA') {
      //         product.route = product.routeCA
      //         product.learnRoute = product.learnRouteCA
      //         console.log('Changed PET Route !')
      //       }
      //     })
      //   }, 900)
      // },
      // createaccount () {
      //   this.$store.dispatch('createUserAndProfileForUser', { email: 'custsomer2@blanket.com', password: '12223334444' })
      // },
      scrollToSection2 () {
        window.scroll({
          top: 700,
          left: 0,
          behavior: 'smooth',
        })
      },
      transition () {
        this.getStarted = !this.getStarted
        setTimeout(() => (this.showTitle = !this.showTitle), 400)
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      filterproducts () {
        console.log('running filter ')
        console.log('products ', this.products)
        console.log('cats ', this.categories)
        this.filteredproducts = []
        this.products.forEach((product) => {
          let intersection = product.categories.filter((entry) =>
            this.showncategories.includes(entry)
          )
          console.log('intersection for ', product.name, ' is ', intersection)
          if (intersection.length > 0) {
            this.filteredproducts.push(product)
          }
        })
        console.log('filtered products are ', this.filteredproducts)
      // this.categories the selected cats
      // this.products the shown products
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
  .bw-dashboard {
    height: 100vh;
  }
}
.zoom {
  transition: transform 0.2s;
}
.zoom:hover {
  transform: scale(1.1);
}
.section1-desktop {
  position: relative;
  display: flex;
  padding-block: 50px;
  padding-inline: 9%;
  height: 600px;
  background-image: url("/img/blanketmain-v1.jpg");
  background-position: center;
  background-size: cover;
}
.section1-xl {
  position: relative;
  display: flex;
  padding-block: 50px;
  padding-inline: 9%;
  height: 800px;
  background-image: url("/img/blanketmain-v1.jpg");
  background-position: center;
  background-size: cover;
}
.section1-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-self: flex-end;
}
.apply-btn-txt-hero {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  align-self: flex-start;
  line-height: 125%;
  letter-spacing: 0.00892857em;
  color: #ffffff;
}
.apply-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 125%;
  letter-spacing: 0.00892857em;
  color: #292f36 !important;
  align-self: flex-start;
}
.section2-desktop {
  position: relative;
  height: 831.86px;
  background: #ffffff;
}
.sec2-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.sec2-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Dark Grey */
  color: #292f36;
}
.sec2-dsk-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 24px;
  gap: 10px;
  background: #ffffff;
  /* Color Palette/CTA Color */
  height: 242px;
  border: 1px solid #aaf0c1;
  /* Box Shadow Light */
  box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
  border-radius: 8px;
}
.prd-card-title {
  position: absolute;
  top: 137px;
  left: 0;
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 110%;
  /* identical to box height, or 33px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Pacific Blue */
  color: #00a1b7;
}
.product-tag {
  position: absolute;
  /* top: 175px; */
  bottom: -5px;
  left: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  /* font-size: 16px; */
  font-size: 14.5px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}
@media screen and (max-width: 360px) {
  .product-tag {
    font-size: 12px;
  }
}
.lrn-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  /* midnight eagle green */
  color: #005f6c;
}
.learn-btn {
  width: 137px;
  height: 33px;
  border: 1.37127px solid #005f6c;
  border-radius: 5.48507px;
  background: #ffffff !important;
}
.sec3-dsk {
  height: 1005px;
  background: url("/img/secondary-knit.jpg");
}
.sec3-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.dash-icons {
  font-size: 48px !important;
}
.sec3-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* or 114% */
  text-align: center;
  /* midnight eagle green */
  color: #ffffff;
}
.sec3-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* gunmetal */
  color: #ffffff;
}
.sec5-dsk {
  position: relative;
  height: 356px;
  background: url("/img/dash-pattern.jpg");
}
.sec5-dsk-phone {
  position: absolute;
  left: 726px;
  top: -78px;
}
.sec5-xl-phone {
  position: absolute;
  left: 1100px;
  top: -78px;
}
.sec5-cnt {
  position: absolute;
  width: 516px;
  height: 157px;
  left: 794px;
  top: 75px;
  left: 214px;
}
.sec5-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  letter-spacing: -0.424019px;
  /* Color Palette/White (100%) */
  color: #ffffff;
}
.sec5-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Color Palette/White (100%) */
  color: #ffffff;
}
.sec6-dsk {
  height: 673px;
  background: #ffffff;
}
.faq-div {
  margin-top: 40px;
  width: 928px;
  height: 407px;
}
.faq-titles {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}
::v-deep .v-expansion-panel {
  border-bottom: 2px solid #aaf0c1 !important;
}
::v-deep .v-expansion-panel-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep .v-expansion-panel::before {
  content: none;
}
.sec7-dsk {
  position: relative;
  height: 570px;
  background: url("/img/partner-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-frame {
  position: absolute;
  width: 580px;
  height: 370px;
  left: 130px;
  top: 86px;
}
.about-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
.generali-logo {
  position: absolute;
  width: 231.7px;
  height: 31.25px;
  left: 641px;
  top: 77px;
}
.stillwater-logo {
  position: absolute;
  width: 252.18px;
  height: 128.24px;
  left: 927.64px;
  top: 0;
}
.trupanion-logo {
  position: absolute;
  width: 232.05px;
  height: 69.27px;
  left: 641px;
  top: 170px;
  display: none;
}
.afficiency-logo {
  position: absolute;
  width: 139.36px;
  height: 52.76px;
  left: 927.64px;
  top: 140px;
}
.tugo-logo {
  position: absolute;
  width: 154px;
  height: 68.96px;
  left: 641px;
  top: 141px;
}
.humania-logo {
  position: absolute;
  width: 154px;
  height: 68.96px;
  left: 641px;
  top: 221px;
  width:245px;
}
.auto-chip {
  position: absolute;
  left: 140px;
  top: 8%;
  height: 75px;
  width: 157px;
}
.travel-chip {
  position: absolute;
  right: 10.7%;
  top: 8%;
  height: 75px;
  width: 115px;
}
.life-chip {
  position: absolute;
  left: 41.7%;
  top: 10%;
  height: 75px;
  width: 130px;
}
.pet-chip {
  position: absolute;
  right: 42%;
  top: 23.3%;
  height: 90px;
  width: 75px;
}
.chip:hover {
  cursor: pointer;
}
.coming-btn {
  width: auto;
  height: 33px;
  border: 1.51871px solid rgba(0, 0, 0, 0.26);
  border-radius: 5.48507px;
  background: #ffffff !important;
}
.coming-btn:hover {
  cursor: default !important;
}
.coming-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.26);
}
.inactive {
  cursor: default !important;
}

.news-section {
  padding: 5rem 4rem 7rem 4rem;
}
.news-section h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.news-section h1.news-title {
  color: #005f6c;
}
.news-section p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
  margin: 1rem 0rem;
}

/*----------------------------TABLET---------------------------*/
.section2-tablet {
  position: relative;
  background: #ffffff;
}
.sec2-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.sec2-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  /* or 38px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Dark Grey */
  color: #292f36;
}
.sec2-dsk-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 24px;
  gap: 10px;
  background: #ffffff;
  /* Color Palette/CTA Color */
  height: 242px;
  border: 1px solid #aaf0c1;
  /* Box Shadow Light */
  box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
  border-radius: 8px;
}
.prd-card-title {
  position: absolute;
  top: 137px;
  left: 0;
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 110%;
  /* identical to box height, or 33px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Color Palette/Pacific Blue */
  color: #00a1b7;
}
/* .product-tag {
position: absolute;
top: 175px;
left: 0;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
} */
.lrn-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  /* midnight eagle green */
  color: #005f6c;
}
.learn-btn {
  width: 137px;
  height: 33px;
  border: 1.37127px solid #005f6c;
  border-radius: 5.48507px;
  background: #ffffff !important;
}
.sec3-tablet {
  background-image: url("/img/secondary-knit.jpg");
  background-position: center;
  background-size: cover;
}
.sec3-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.dash-icons {
  font-size: 48px !important;
}
.sec3-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* or 114% */
  text-align: center;
  /* midnight eagle green */
  color: #ffffff;
}
.sec3-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* gunmetal */
  color: #ffffff;
}
.sec4-tablet {
  background: #ffffff;
}
.sec4-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Dark Green */
  color: #005f6c;
}
.sec4-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.sec4-icon {
  font-size: 36px !important;
}
.sec4-qt {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}
.sec4-qt-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  color: #292f36;
}
.sec5-tablet {
  background-image: url("/img/dash-pattern.jpg");
  background-position: center;
  background-size: cover;
}
.sec5-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.sec5-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Color Palette/White (100%) */
  color: #ffffff;
}
.sec6-dsk {
  height: 673px;
  background: #ffffff;
}
.faq-div {
  margin-top: 40px;
  width: 928px;
  height: 407px;
}
.faq-titles {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}
::v-deep .v-expansion-panel {
  border-bottom: 2px solid #aaf0c1 !important;
}
::v-deep .v-expansion-panel-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep .v-expansion-panel::before {
  content: none;
}
.sec7-tablet {
  background-image: url("/img/partner-background.jpg");
  background-position: center;
  background-size: cover;
}
.about-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
.generali-logo-tablet {
  width: 231.7px;
  height: 31.25px;
}
.stillwater-logo-tablet {
  width: 252.18px;
  height: 128.24px;
}
.trupanion-logo-tablet {
  width: 232.05px;
  height: 69.27px;
  display: none;
}
.afficiency-logo-tablet {
  width: 139.36px;
  height: 52.76px;
}
.tugo-logo-tablet{
  width: 154px;
  height: 68.96px;
}
.humania-logo-tablet{
  width: 265px;
  height: 68.96px;
}
.auto-chip {
  height: 75px;
  width: 157px;
}
.travel-chip {
  position: absolute;
  right: 10.7%;
  top: 8%;
  height: 75px;
  width: 115px;
}
.life-chip {
  position: absolute;
  left: 41.7%;
  top: 10%;
  height: 75px;
  width: 130px;
}
.pet-chip {
  position: absolute;
  right: 42%;
  top: 23.3%;
  height: 90px;
  width: 75px;
}
.chip:hover {
  cursor: pointer;
}
.coming-btn {
  width: 137px;
  height: 33px;
  border: 1.51871px solid rgba(0, 0, 0, 0.26);
  border-radius: 5.48507px;
  background: #ffffff !important;
  font-size: 12px;
}
.coming-btn:hover {
  cursor: default !important;
}
.coming-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.26);
}
.inactive {
  cursor: default !important;
}

.news-section-tablet {
  padding: 3rem 4rem 5rem 3rem;
}
.news-section-tablet h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.news-section-tablet h1.news-title {
  color: #005f6c;
  font-size: 25px;
}
.news-section-tablet p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 2.3vw;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
  margin: 1rem 0rem;
}

/*---------------------------MOBILE---------------------------*/
.section1-mobile {
  position: relative;
  height: 375px;
  background-image: url("/img/blanketmain-v1.jpg");
  background-position: center;
  background-size: cover;
}

.section1-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.sec3-mobile {
  background-image: url("/img/secondary-knit-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.sec5-mobile {
  background-image: url("/img/dash-pattern-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.sec7-mobile {
  background-image: url("/img/partner-background-mobile.jpg");
  background-position: center;
  background-size: cover;
}

.news-section-mobile {
  padding: 2rem 0rem 3rem 0rem;
}
.news-section-mobile h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.news-section-mobile h1.news-title {
  color: #005f6c;
  font-size: 25px;
}
.news-section-mobile p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-weight: 300;
  font-size: 19px;
  line-height: 150%;
  color: #292f36;
  margin: 1rem 0rem;
}
@media screen and (min-width: 300px) and (max-width:500px) {
  .coming-btn,
  .learn-btn {
    width:180px;
  }
  .coming-txt,
  .learn-btn,
  .lrn-btn-txt{
    font-size: 13px;
  }
}

@media screen and (min-width: 600px) {
  .coming-btn,
  .learn-btn {
    width:auto!important;
    max-width: 235px!important;
  }
  .coming-txt,
  .learn-btn{
    font-size: 15px;
  }
  .learn-btn{
    width:auto!important;
    max-width: 235px!important;
  }
}
 .hero-custom-style{
    padding-block: 4%;
  }
</style>
